<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isShow"
      width="30%"
      :before-close="handleClose"
      center
      @open="openFn"
    >
      <div class="content">
        <PassWordInput
          ref="pwds"
          @inputFinish="inputFinish"
          class="password-box"
        ></PassWordInput>
        <!-- <el-button @click="submit">确定</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
    };
  },
  props: {
    isShow: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "请输入密码",
    },
  },
  components: {
    PassWordInput: () => import("./PassWordInput.vue"),
  },
  methods: {
    openFn() {
      this.$refs.pwds.msg = "";
    },
    handleClose() {
      this.$emit("close");
    },
    inputFinish(e) {
      this.password = e;
      if (this.password.length == 6) {
        // 输完密码之后,再次弹出
        this.$emit("password2", this.password);
      }
    },
    // submit () {
    //   this.isShow = false
    //   this.$router.go(-1)
    //   console.log(this.password)
    // }
  },
};
</script>

<style lang="scss" scoped>
.content {
  text-align: center;
  .password-box {
    min-height: 200px;
  }
}
</style>
